var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"panel panel-primary"},[_vm._m(0),_c('form',{attrs:{"id":"formPeserta","enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return _vm.postData.apply(null, arguments)}}},[_c('div',{staticClass:"panel-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"full_name"}},[_vm._v("Nama Lengkap")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.full_name),expression:"form.full_name"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.full_name,
                        },attrs:{"type":"text","name":"full_name"},domProps:{"value":(_vm.form.full_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "full_name", $event.target.value)}}}),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.full_name)}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"username"}},[_vm._v("Username")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.username),expression:"form.username"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.username,
                        },attrs:{"type":"text","name":"username"},domProps:{"value":(_vm.form.username)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "username", $event.target.value)}}}),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.username)}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"phone_number"}},[_vm._v("Nomor HP/WA")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.phone_number),expression:"form.phone_number"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.phone_number,
                        },attrs:{"type":"number","name":"phone_number"},domProps:{"value":(_vm.form.phone_number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "phone_number", $event.target.value)}}}),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.phone_number)}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.formValidate.password,
                        },attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('small',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.password)}})])]),_c('div',{staticClass:"col-md-3"},[_c('div',{staticClass:"form-group"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirm_password),expression:"form.confirm_password"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.formValidate.confirm_password,
                        },attrs:{"type":"password","placeholder":"Ulangi Password"},domProps:{"value":(_vm.form.confirm_password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "confirm_password", $event.target.value)}}}),_c('small',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.confirm_password)}})])]),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"level"}},[_vm._v("Level User")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.level),expression:"form.level"}],staticClass:"form-control m-b-5",class:{
                          'is-invalid': _vm.formValidate.level,
                        },attrs:{"name":"level"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "level", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_c('option',{attrs:{"value":"admin"}},[_vm._v("Admin")]),_c('option',{attrs:{"value":"loket"}},[_vm._v("Loket DLH")]),_c('option',{attrs:{"value":"kelurahan"}},[_vm._v("Loket Kelurahan")]),_c('option',{attrs:{"value":"penagih"}},[_vm._v("Verifikator Kecamatan")]),_c('option',{attrs:{"value":"satgas"}},[_vm._v("Satgas Kelurahan")]),_c('option',{attrs:{"value":"guest"}},[_vm._v("Guest")])]),_c('div',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.formValidate.level)}})])]),(
                      _vm.form.level == 'kelurahan' ||
                      _vm.form.level == 'penagih' ||
                      _vm.form.level == 'satgas'
                    )?_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"district"}},[_vm._v("Kecamatan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.district),expression:"form.district"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.formValidate.district,
                        },attrs:{"name":"district","id":"district"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "district", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.fetchVillages($event)}]}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.districtList),function(districts){return _c('option',{key:districts.id_kec,domProps:{"value":districts.id_kec}},[_vm._v(" "+_vm._s(districts.nama_kecamatan)+" ")])})],2)])]):_vm._e(),(_vm.form.level == 'kelurahan' || _vm.form.level == 'satgas')?_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"village"}},[_vm._v("Kelurahan : ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.village),expression:"form.village"}],staticClass:"form-control",class:{
                          'is-invalid': _vm.formValidate.village,
                        },attrs:{"name":"village","id":"village"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "village", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih")]),_vm._l((_vm.villageList),function(villages){return _c('option',{key:villages.id_kel,domProps:{"value":villages.id_kel}},[_vm._v(" "+_vm._s(villages.nama_kelurahan)+" ")])})],2)])]):_vm._e()])])])]),_c('div',{staticClass:"panel-footer"},[_c('a',{staticClass:"btn btn-default",attrs:{"href":"javascript:window.history.go(-1);"}},[_vm._v("Batal")]),_c('button',{staticClass:"btn btn-teal pull-right",attrs:{"type":"button","disabled":this.loading},on:{"click":function($event){return _vm.postData()}}},[(this.loading)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.id ? "Update" : "Submit")+" ")])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"panel-heading bg-dark text-light"},[_c('h3',{staticClass:"panel-title bold"},[_vm._v("Form Pengguna")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"password"}},[_vm._v("Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"confirm_password"}},[_vm._v("Ulangi Password "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])
}]

export { render, staticRenderFns }